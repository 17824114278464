import log from "loglevel";
import { useContext, useState, useEffect, Fragment } from 'react'
import { Transition, Dialog} from '@headlessui/react'
import StallholderSearch2 from "@components/StallholderSearch2";
import useStallholder from "@hooks/useStallholder";

import FloorContext from "@contexts/FloorContext";
import { FLOORS } from "@contexts/FloorContext";
import { Stallholder} from '@sharedtypes/Stallholder';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'


const TillDetails = () => {

  const {floor} = useContext(FloorContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const stallholderContext = useStallholder();

  useEffect(() => {

    log.info("TillDetails:", floor);

    return () => {
    };
  }, [floor]); 

  function close() {
    setIsOpen(false);
  }

  function open() {
    setIsOpen(true);
  }

  function setTillDealer(dealer: Stallholder | undefined) {
    stallholderContext.setStallholder(dealer);
  }

  return (

    <div>
      <button
          onClick={open}
          className="text-slate-400 rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {
            (stallholderContext.stallholder === undefined) ?
              <div className="flex font-bold text-red-400 items-center"><ExclamationCircleIcon className="h-6 w-6" />
                <span className="ml-2">Set till operator</span>
              </div> :
              <div>{FLOORS[floor]}: {stallholderContext.stallholder?.name}</div>
          }
        </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/85" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Till Details
                  </Dialog.Title>
                  <div className="mt-2">
                    
                    <h1 className="text-3xl font-extrabold mb-2">{FLOORS[floor]} Floor</h1>
                    <h2 className="text-2xl font-bold mb-4">Dealer on till:</h2>
                    
                    <StallholderSearch2 setter={setTillDealer} stallholder={stallholderContext.stallholder}/>
                  </div>        

                  <div className="flex justify-center mt-4">
                    <button
                      type="button"
                      className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={close}
                    >
                      Close                     
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default TillDetails;
