
import { useState, useEffect} from 'react'
import log from "loglevel";
import dateFormat from "dateformat";

import useTransactions from "@hooks/useTransactions2";
import {CompletedTransaction} from "@sharedtypes/CompletedTransactions";

import Table from "@shared/Table";
import {CASH, DEBIT, CREDIT, CONTRA, CREDIT_FEE, DEBIT_FEE} from '@constants/payment_types'
import {GOOD, REFUNDED} from '@constants/status_types'
import { formatPrice } from "@shared/Utils";
import {RefreshButton} from "@shared/Buttons";
import currency from 'currency.js';

type TransactionsProps = {
  floor: number;
  lastReconciliation: Date;
};

const ReconciliationTransactions = ({ floor, lastReconciliation }: TransactionsProps) => {

  const [today] = useState<Date>(new Date());
  const [yesterday] = useState<Date>(new Date(new Date().setDate(new Date().getDate()-1)));
  const [filteredYesterday, setFilteredYesterday] = useState<CompletedTransaction[]>([]);
  const [filteredToday, setFilteredToday] = useState<CompletedTransaction[]>([]);

  const {isLoading: todaysIsLoading, data: todaysTransactions, refetch: todayRefetch} = useTransactions(today, floor);
  const {isLoading: yesterdaysIsLoading, data: yesterdaysTransactions, refetch: yesterdayRefetch} = useTransactions(yesterday, floor);
  const [showSpinner, setShowSpinner] = useState(false);

  log.debug("lastReconciliation:", lastReconciliation);

  useEffect(() => {
    
    log.info("todays transactionSet", todaysIsLoading, todaysTransactions);
    
    if (!todaysIsLoading) {
      //setTransactions([]);
    }

    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor, todaysTransactions, todaysIsLoading]); 

  useEffect(() => {
    
    log.info("yesterdays transactionSet", yesterdaysIsLoading, yesterdaysTransactions);
    
    if (!yesterdaysIsLoading) {
      //setTransactions([]);
      var filtered : Array<CompletedTransaction> = [];
      filtered = filterByDate(yesterdaysTransactions, filtered, lastReconciliation);

      setFilteredYesterday(filtered);
    }

    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor, yesterdaysTransactions, yesterdaysIsLoading]); 

  useEffect(() => {
    
    log.info("todays transactionSet", todaysIsLoading, todaysTransactions);
    
    if (!todaysIsLoading) {
      log.debug("XXX:", todaysTransactions);

      //setTransactions([]);
      var filtered : Array<CompletedTransaction> = [];
      filtered = filterByDate(todaysTransactions, filtered, lastReconciliation);
      setFilteredToday(filtered);
    }

    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floor, todaysTransactions, todaysIsLoading]); 

  async function refresh() {
    setShowSpinner(true);
    await todayRefetch();
    await yesterdayRefetch();
    setShowSpinner(false)
  }

  return (
    <>
    {
      (todaysIsLoading || yesterdaysIsLoading) ? <></> :
      <div>
        <RefreshButton onClick={refresh} showSpinner={showSpinner}/>
        <TransactionsByTypeTable label="Cash" todaysTransactions={filteredToday} yesterdaysTransactions={filteredYesterday} paymentType={CASH} statusType={GOOD}/>

        <TransactionsByTypeTable label="Contra" todaysTransactions={filteredToday} yesterdaysTransactions={filteredYesterday} paymentType={CONTRA} statusType={GOOD}/>

        <TransactionsByTypeTable label="Credit" todaysTransactions={filteredToday} yesterdaysTransactions={filteredYesterday} paymentType={CREDIT} statusType={GOOD}/>
        <TransactionsByTypeTable label="Debit" todaysTransactions={filteredToday} yesterdaysTransactions={filteredYesterday} paymentType={DEBIT} statusType={GOOD}/>
        <TransactionsByTypeTable label="Refunded Debit" todaysTransactions={filteredToday} yesterdaysTransactions={filteredYesterday} paymentType={DEBIT} statusType={REFUNDED}/>

        <Summary today={filteredToday} yesterday={filteredYesterday}/>
      </div>
    }
    </>
  );
}

function filterByDate(transactions: CompletedTransaction[], parent: CompletedTransaction[], date: Date) {

  transactions.forEach((transaction: CompletedTransaction) => {

    const timestamp = new Date(transaction.timestamp);
    log.info("compare:", timestamp, date);

    if (timestamp > date) {
      //log.info("in");
      parent.push(transaction);
    }
    else {
      log.info("out");
    }
  });

  return parent;
}

const columns = [
  {
    "id": "stallholder",
    "label": "Dealer",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1"},
    "headerStyle": "",
    "format": (cell: any, row: {}, index: number) => { 
      return cell;
    }
  },
  {
    "id": "price",
    "label": "Net",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1 "},
    "headerStyle": "pl-2 py-1 pr-4",
    "format": (cell: any, row: {}, index: number) => { 

      const transaction = row as CompletedTransaction;
      if (transaction.status === GOOD) {
        if (transaction.paymentType === DEBIT)
          return formatPrice(transaction.price - (transaction.price * DEBIT_FEE));
        if (transaction.paymentType === CREDIT)
          return formatPrice(transaction.price - (transaction.price * CREDIT_FEE));
        else
          return formatPrice(transaction.price);
      }
      else if (transaction.status === REFUNDED) {
        if (transaction.paymentType === DEBIT) {
          return (transaction.price * DEBIT_FEE) * 2;
        }
      }
      return <div className="">-</div>
    }
  },
  {
    "id": "stockid",
    "label": "Stock ID",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1"},
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}) => { 
      return (
        <p className=""> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "description",
    "label": "Description",
    "cellStyle": (row: number) => {return "bg-white py-1"},
    "headerStyle": "py-1",
    "format": (cell: any, row: {}, index: number) => { 
      return (
        <p className=""> 
          {cell}
        </p>
      );
    }
  },
  {
    "id": "timestamp",
    "label": "Time",
    "cellStyle": (row: number) => {return "bg-white pl-2 py-1"},
    "headerStyle": "pl-2 py-1",
    "format": (cell: any, row: {}, index: number) => { 

      const date = new Date(cell);

      if (isToday(date)) return <>Today {dateFormat(date, "HH:MM")}</>;
      return <>Yesterday {dateFormat(date, "HH:MM")}</>;
    }
  }
]

type TransactionsByTypeTableProps = {
  label: string;
  todaysTransactions: CompletedTransaction[];
  yesterdaysTransactions: CompletedTransaction[];
  paymentType: number;
  statusType: number;
};

const today = new Date();
function isToday(date: Date) {
  return (today.toDateString() === date.toDateString());
}

function filterTransactionsByType(paymentType: number, statusType: number, transactions: CompletedTransaction[], parent: CompletedTransaction[]) {

  transactions.forEach((transaction: CompletedTransaction) => {

    if (transaction.paymentType === paymentType && transaction.status === statusType) {
      //log.info("in");
      parent.push(transaction);
    }
  });
}

function sortOldestFirst(transactions: CompletedTransaction[]){

  return transactions.sort((obj1: Object, obj2: Object) => {
    const key1 = "timestamp" as keyof typeof obj1;
    const key2 = "timestamp" as keyof typeof obj2;

    if (obj1[key1] > obj2[key2]) {
        return 1;
    }

    if (obj1[key1] < obj2[key2]) {
        return -1;
    }

    return 0;
  });  
}

const TransactionsByTypeTable = ({ label, todaysTransactions, yesterdaysTransactions, paymentType, statusType }: TransactionsByTypeTableProps) => {
  
  const [transactions, setTransactions] = useState<CompletedTransaction[]>([]);

  useEffect(() => {
  
    var filtered : Array<CompletedTransaction> = [];
    filterTransactionsByType(paymentType, statusType, todaysTransactions, filtered);
    filterTransactionsByType(paymentType, statusType, yesterdaysTransactions, filtered);
    filtered = sortOldestFirst(filtered);
    setTransactions(filtered);

  return () => {
  };
}, [todaysTransactions, yesterdaysTransactions, paymentType, statusType]);   

  return (
    <>
      <h1 className="mb-4 mt-6 text-2xl font-bold text-left">{label}</h1>
      <Table data={transactions} columns={columns} />
      {
        transactions.length > 0 ? <></> :
        <div>NONE</div>
      }
    </>
  );
}

const summaryCols = [

  {
    "id": "label",
    "label": "",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    "headerStyle": "pl-2 py-1",
  },
  {
    "id": "gross",
    "label": "Gross",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    //"cellStyle": "pl-2 py-1 text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },
  {
    "id": "fees",
    "label": "Fees",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    //"cellStyle": "pl-2 py-1 text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },
  {
    "id": "net",
    "label": "Net",
    "cellStyle": (row: number) => "pl-2 py-1 text-lg",
    //"cellStyle": "pl-2  py-1 text-lg",
    "headerStyle": "pl-2  py-1",
    "format": (cell: any, row: {}, index: number) => { 

      return formatPrice(cell);
    }
  },

]

type SummaryProps = {
  today: CompletedTransaction[];
  yesterday: CompletedTransaction[];
};

type SummaryType = {
  label: string;
  gross: number;
  fees: number;
  net: number;
};

const Summary = ({ today, yesterday }: SummaryProps) => {

  const [summary, setSummary] = useState<SummaryType[]>([]);

  useEffect(() => {
    
      log.info("Summary");
      const s = {
        cash: 0.0,
        credit: 0.0,
        debit: 0.0,
        contra: 0.0,
        refundedDebit: 0.0
      }


      processTransactions(today, s);
      processTransactions(yesterday, s);

      const debitFee = currency(s.debit).multiply(DEBIT_FEE);
      const debitRefunded = currency(s.refundedDebit).multiply(DEBIT_FEE).multiply(2);
      const totalDebitFees = debitFee.subtract(debitRefunded);
      const debitNet = currency(s.debit).subtract(totalDebitFees);

      const creditFee = currency(s.credit).multiply(CREDIT_FEE);
      const creditNet = currency(s.credit).subtract(creditFee);
      
      const totalCard = currency(s.debit).add(s.credit);
      const totalCardNet = debitNet.add(creditNet);
      const totalCardFee = totalCard.subtract(totalCardNet);

      const newSummary : Array<SummaryType> = [];
      newSummary.push({label: "Debit", gross: s.debit, fees: totalDebitFees.value, net: debitNet.value});
      newSummary.push({label: "Credit", gross: s.credit, fees: creditFee.value, net: creditNet.value});
      newSummary.push({label: "Card total", gross: totalCard.value, fees: totalCardFee.value, net: totalCardNet.value});
      newSummary.push({label: "Cash", gross: s.cash, fees: 0.00, net: s.cash});
      newSummary.push({label: "Total", gross: s.cash + s.debit + s.credit, fees: totalCardFee.value, net: s.cash + debitNet.value + creditNet.value});
      newSummary.push({label: "Contra", gross: s.contra, fees: 0.00, net: s.contra});

      setSummary(newSummary);
    
    return () => {
    };
  }, [today, yesterday]); 

  function processTransactions(transactions: CompletedTransaction[], s:{cash: number, credit: number, debit: number, contra: number, refundedDebit: number}) {
    log.info("processTransactions");
    transactions.forEach((transaction: CompletedTransaction) => {
      if (transaction.status === GOOD) {

        if (transaction.paymentType === CASH)
          s.cash += transaction.price;
        else if (transaction.paymentType === DEBIT)
          s.debit += transaction.price;
        else if (transaction.paymentType === CREDIT)
        s.credit += transaction.price;
        else if (transaction.paymentType === CONTRA)
        s.contra += transaction.price;
        else {
          log.error("Sales processed unknown payment type:", transaction.paymentType);
        }
      }
      else if (transaction.status === REFUNDED && transaction.paymentType === DEBIT) {
        s.refundedDebit += transaction.price;
      }
    });    
  }


  return (
    <div className="mt-24 w-96">
      <h1 className="font-extrabold text-2xl ">Summary</h1>
      <Table data={summary} columns={summaryCols} />
    </div>
  )
}

export default ReconciliationTransactions;
