import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useState } from 'react'
import ErrorPage from "@screens/ErrorPage";
import Sales from "@components/Sales";
import Dealers from "@components/Dealers";
import Cash from "@components/Cash";
import Reconciliation from "@components/Reconciliation";
import Till from "@components/Till";
import log, { LogLevelNumbers } from "loglevel";
import { TillAuthProvider } from "@contexts/TillAuthContext";
import { TillAPIServiceProvider} from "@contexts/TillAPIServiceContext";
import { StallholdersProvider } from "@contexts/StallholdersContext";
import { StallholderProvider } from "@contexts/StallholderContext";
//import { EnforceStockIDsProvider } from "@contexts/EnforceStockIDsContext";
import { FloorProvider } from "@contexts/FloorContext";
import { StallholderHistoryProvider } from "@contexts/StallholderHistoryContext";
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { NavigationProvider } from "@shared/ContextProviders/NavigationContext";
import { QueryClient, QueryClientProvider } from 'react-query'
import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { COGNITO_APP_ID, COGNITO_POOL_ID } from "@constants/urls";


import './App.css';
import '@aws-amplify/ui-react/styles.css';


const queryClient = new QueryClient()

try {
  const level: LogLevelNumbers = 1; //process.env.REACT_APP_LOG_LEVEL;

  console.log("Log level set to " + level);
  log.setLevel(level);
} catch (error) {
  console.log("LOG LEVEL COULD NOT BE READ");
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Till />,
    errorElement: <ErrorPage />,
  },
  {
    path: "home",
    element: <Till />,
    errorElement: <ErrorPage />,
  },
  {
    path: "sales",
    element: <Sales />,
    errorElement: <ErrorPage />,
  },
  {
    path: "dealers",
    element: <Dealers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "cash",
    element: <Cash />,
    errorElement: <ErrorPage />,
  },
  {
    path: "reconciliation",
    element: <Reconciliation />,
    errorElement: <ErrorPage />,
  },
  {
    path: "signout",
    element: <SignOut />,
  }

]);

//import config from './amplifyconfiguration.json';
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: COGNITO_APP_ID,
      userPoolId: COGNITO_POOL_ID,
    }
  }
});

export function App({ signOut, user }: WithAuthenticatorProps) {

  const [pages] = useState([
    { name: 'Till', href: 'home', index: 0},
    { name: 'Sales', href: 'sales', index: 1 },
    { name: 'Dealers', href: 'dealers', index: 2 },
    { name: 'Cash', href: 'cash', index: 3 },
    { name: 'Reconciliation', href: 'reconciliation', index: 4 },
  ]);

  
  return (
    <div className="App">
      
      <NavigationProvider pages={pages}>
        <TillAuthProvider>
          <FloorProvider>
              <TillAPIServiceProvider>
                <QueryClientProvider client={queryClient}>
                    <StallholdersProvider>
                      <StallholderProvider>
                        <StallholderHistoryProvider>
                          <RouterProvider router={router} />
                        </StallholderHistoryProvider>
                      </StallholderProvider>
                    </StallholdersProvider>
                </QueryClientProvider>
              </TillAPIServiceProvider>
          </FloorProvider>
        </TillAuthProvider> 
      </NavigationProvider>

    </div>
  );
}

function SignOut() {

  const navigate = useNavigate();


  try {
    signOut();
    navigate("/");
  } catch (error) {
    log.warn('error signing out: ', error);
  }


  return (<></>);
}

export default withAuthenticator(App, { hideSignUp: true });
