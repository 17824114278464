import { ReactNode, createContext } from "react";
import { useState, useEffect } from "react";
import { Cache } from 'aws-amplify/utils';

const FloorContext = createContext({
	floor: -1,
	setFloor: (floor: number) => {},
});

type FloorContextProps = {
  children?: ReactNode
};

export const FLOORS = ['Ground', 'Middle', 'Top'];

export const FloorProvider = ({ children }: FloorContextProps) => {

  const [floor, setFloor] = useState<number>(-1);

  useEffect(() => {

    Cache.getItem("FLOOR")
    .then(result => {
      setFloor(parseInt(result));
    })
    return () => {
    };
  }, []);   

  return (
    <FloorContext.Provider value={{floor, setFloor}}>
      {children}
    </FloorContext.Provider>
  );
};

export default FloorContext;
