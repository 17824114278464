import { useQuery } from 'react-query'
import useTillAPIService from "@hooks/useTillAPIService";
import log from "loglevel";
import { Stallholder} from '@sharedtypes/Stallholder';

export type CompletedTransaction = {

  id: string;
  stallholder: Stallholder | undefined;
  price: number;
  paymentType: number;
  till: string;
  timestamp: string;
  floor: number;
  stockid: string;
  description: string;
  status: number;
};

type TransactionsQueryType = (
  date: Date,
  floor: number
) => any;

const useTransactions: TransactionsQueryType = (
  date: Date,
  floor: number
) => {
  //const [transactions, setTransactions] = useState<Transaction[]>([]);
  const apiService  = useTillAPIService();
  const created = date.toISOString().split('T')[0];

  return useQuery('transactions:' + created + "/" + floor, 
      async () => 
      apiService.fetch("getTransactions", {date: created, floor: floor})
      .then((data: any) => {
          log.debug("Sales fetched", data, floor);
  
          if (data !== undefined) {
            data.forEach((transaction: any) => {
              transaction.timestamp = transaction.created + "T" + transaction.time
            });
  
            const sortedArray = data.sort((obj1: Object, obj2: Object) => {
              const key1 = "timestamp" as keyof typeof obj1;
              const key2 = "timestamp" as keyof typeof obj2;
        
              if (obj1[key1] < obj2[key2]) {
                  return 1;
              }
          
              if (obj1[key1] > obj2[key2]) {
                  return -1;
              }
          
              return 0;
            });        
            //setTransactions(sortedArray);
            return sortedArray;
            //log.info("sorted " + JSON.stringify(sortedArray));
          }
          log.warn("Error fetching transactions");
          return [];
        })
      .catch(function (error) {
        log.warn("fetch error:" + error);
      }),
      {
        refetchInterval: 60000,
        refetchOnWindowFocus: false,
        refetchOnMount: false
      } 
  )
}

export default useTransactions;
