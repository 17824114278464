import Shell from "@shared/Shell";
import useStallholder from "@hooks/useStallholder";
import FloorContext from "@contexts/FloorContext";
import { useEffect, useContext, useState, Fragment } from 'react'
import useTillAPIService from "@hooks/useTillAPIService";
import log from "loglevel";
import useCashMarks from "@hooks/useCashMarks";
import dateFormat from "dateformat";
import useLastReconciliation from "@hooks/useLastReconciliation";
import ReconciliationTransactions from "@components/ReconciliationTransactions";
import {  Transition, Dialog } from '@headlessui/react'
import {SpinnerWrapper} from "@shared/Spinner";

// investigate why so many remaps for cash
export function Reconciliation() {

  const stallholderContext = useStallholder();
  const {floor} = useContext(FloorContext);
  const apiTillService  = useTillAPIService();
  const {data, isLoading, refetch} = useCashMarks();
  const {data: last} = useLastReconciliation(floor);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    
    log.info("recs", last);
    
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last, isLoading]); 


  async function reconcile() {

    if (stallholderContext.stallholder !== undefined) {

      apiTillService.fetch("markReconciliation", 
        {
          till: stallholderContext.stallholder.id,
          floor: floor
        }
        )
        .then((data) => {
          log.info("added mark " + JSON.stringify(data));
          refetch();
          })
        .catch(function (error) {
          log.info("Error editing transaction:" + error);
        }
      )
    }

  }

  function cancel() {
    setShowDialog(false);
  }

  async function confirm() {
    await reconcile();
    setShowDialog(false);
  }

  return (
    <>
      <Shell pageIndex={4}>
        <div>
          <CompleteDialog isOpen={showDialog} cancel={cancel} confirm={confirm}/>

          <h1 className="text-xl font-bold text-left">Reconciliation</h1>

          {
            (data === undefined || floor === -1 || isLoading) ? <SpinnerWrapper />  :
            <>
              <p className="mt-10text-l font-bold text-left">
                Last reconciled {dateFormat(last, "dddd, mmmm dS, HH:MM")}
              </p>
              <button 
                className="mt-10 mb-12 mr-2 px-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                onClick={()=>setShowDialog(true)}
              >
                Reconciliation complete
              </button>
              <ReconciliationTransactions floor={floor} lastReconciliation={new Date(last)}/>            
            </>
          }

        </div>
      </Shell>    
    </>
  );
}



type CompleteDialogProps = {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
};

const CompleteDialog = ({ isOpen, cancel, confirm }: CompleteDialogProps) => {

  return (

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                    Are you sure you want to mark as reconciled?
                  </div>        

                  <div className="mt-4">
                    <button
                      type="button"
                      className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={confirm}
                    >
                      Yes, continue
                    </button>
                    <button
                      type="button"
                      className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={cancel}
                    >
                      No, cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default Reconciliation;
