import log from "loglevel";
import { useState, useContext, useEffect } from "react";
import useTillAPIService from "@hooks/useTillAPIService";
import useStallholder from "@hooks/useStallholder";
import FloorContext from "@contexts/FloorContext";
import { Stallholder} from '@sharedtypes/Stallholder';
import { parsePrice } from "@shared/Utils";

export type TillItem = {
  stallholder: string;
  description: string;
  stock: string;
  price: number;
};    

export const ZERO = "0.00"

type TillQueryType = (
  //completePurchase: () =>{},
) => any;

const useTill: TillQueryType = () => {
  
  const [purchases, setPurchases] = useState<TillItem[]>([])
  const [transactionIndex, setTransactionIndex] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const apiService  = useTillAPIService();
  const stallholderContext = useStallholder();
  const {floor} = useContext(FloorContext);
  const [price, setPrice] = useState<string>(ZERO);
  const [dealer, setDealer] = useState<Stallholder | undefined>(undefined);
  const [stock, setStock] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [processingPurchases, setProcessingPurchases] = useState(false);

  useEffect(() => {

    if ( dealer !== undefined) {
      const newPurchases = [...purchases];

      if (newPurchases.length === transactionIndex) {
        newPurchases.push({price: 0.00 , stallholder: dealer.dealer_id, description:"", stock:""});
      }
      else {
        purchases[transactionIndex].stallholder = dealer.dealer_id;
      }

      setPurchases(newPurchases);   
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer]); 
  
  useEffect(() => {

    const priceAsNumber = parsePrice(price);

    if (priceAsNumber > 0 && purchases.length > 0) {

      purchases[transactionIndex].price = priceAsNumber;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
      calculateTotal();
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]); 

  useEffect(() => {

    if (stock !== undefined && stock.length > 0 && purchases.length > 0) {

      purchases[transactionIndex].stock = stock;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock]); 

  useEffect(() => {

    if (description !== undefined && description.length > 0 && purchases.length > 0) {

      purchases[transactionIndex].description = description;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]); 

  function calculateTotal() {

    var total = 0.00;
  
    purchases.forEach((tillItem: TillItem) => {
        
      total = total + tillItem.price;
  
    });
    setTotal(total);
  }

  function completePurchase(paymentType: number ) {
    
    if (apiService !== null) {
      log.info("apiService not null", purchases.length);

      if (purchases.length > 0) {
        if (stallholderContext.stallholder !== undefined) {

          setProcessingPurchases(true);

          const promise = apiService.addTransactions(purchases, floor, stallholderContext.stallholder.dealer_id, paymentType)
      
          promise.then(async function(value) {
      
            log.info("response from transactions posting : " + JSON.stringify(value.data));
          })
          .catch(function(error) {
            console.error(error);
          })    
          .finally(() => {
            setProcessingPurchases(false);
            log.debug('transaction completed');
          });
        }
      }
    }
    else {
      log.info("apiService null");
    }
  }

/* 
  function completePurchase(paymentType: number ) {

    setProcessingPurchases(true);

    if (apiService !== null) {
      log.info("apiService not null", purchases.length);
      next(paymentType);
    }
    else {
      log.info("apiService null");
    }
  }

  
  function processItem(item: TillItem, paymentType: number  ) {
  
    if (stallholderContext.stallholder !== undefined) {
      const promise = apiService.addTransaction(item, floor, stallholderContext.stallholder.id, paymentType)
  
      promise.then(async function(value) {
  
        log.info("response from transaction posting : " + JSON.stringify(value.data));
        next(paymentType);
      })
      .catch(function(error) {
        console.error(error);
      });      
    }
  }
  
  function next(paymentType: number) {
    if (purchases.length > 0) {
      const item = purchases.pop() ;
      if (item !== undefined)
        processItem(item, paymentType);  
    }
    else
      setProcessingPurchases(false);
  }
*/
  function addItemToSale() {

    setTransactionIndex(transactionIndex+1);
    setDescription("");
    setStock("");
    setTimeout(function() {
      
    }, 100);
  }

  return [total, completePurchase, purchases, addItemToSale, description, price, stock, dealer, setPrice, setDealer, setStock, setDescription, processingPurchases];
}

export default useTill;
