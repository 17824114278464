import { ReactNode, createContext } from "react";
import log from "loglevel";
import axios from "axios";
import { JWT } from 'aws-amplify/auth';
import useTillAuth from "@hooks/useTillAuth";
import { TillItem } from "@hooks/useTill";
import { API_URL } from "@constants/urls";

const TillAPIServiceContext = createContext<TillAPIServiceContextValue | undefined>(undefined);


type TillAPIServiceContextProps = {
  children?: ReactNode
};

export type TillAPIServiceContextValue = {
  addTransactions: (items: TillItem[], floor: number, till: string, paymentType: number)  => Promise<any>;
  addTransaction: (item: TillItem, floor: number, till: string, paymentType: number)  => Promise<any>;
  fetch: (call: string, data: {}) => Promise<any>;
};

export const TillAPIServiceProvider = ({ children }: TillAPIServiceContextProps) => {
  const jwt = useTillAuth();

  const addTransactions = async (items: TillItem[], floor: number, till: string, paymentType: number) => {
    log.info("add transactions ");
    
    return jwt?.getJwtToken()
      .then((token) => {
        log.info("API Service: " + JSON.stringify(token));
        return addTransactionsImpl(token.tokens.idToken, items, floor, till, paymentType);
      })
      .catch(function (error) {
        log.info("auth error:" + error);
      })
  }

  const addTransaction = async (item: TillItem, floor: number, till: string, paymentType: number) => {
    log.info("add transaction ");
    
    return jwt?.getJwtToken()
      .then((token) => {
        log.info("API Service: " + JSON.stringify(token));
        return addTransactionImpl(token.tokens.idToken, item, floor, till, paymentType);
      })
      .catch(function (error) {
        log.info("auth error:" + error);
      })
  }

  const fetch = async (call: string, data: {}) => {
    log.debug("fetch ", call, data);
    
    return jwt?.getJwtToken()
    .then((token) => {
      //log.info("API Service: ",token);
      return fetchData(token.tokens.idToken, call, data);
    })
    .catch(function (error) {
      log.info("auth error:" + error);
    })
  }

  const value: TillAPIServiceContextValue = {
    addTransactions,
    addTransaction,
    fetch
  }

  return (
    <TillAPIServiceContext.Provider value={value}>
      {children}
    </TillAPIServiceContext.Provider>
  );
};

const POST = "POST";
//const GET = "GET";

function addTransactionImpl(jwt: JWT, item: TillItem, floor: number, till: string, paymentType: number) {

  return callWithAuthorization(POST, jwt, "addTransaction", {}, {stallholder: item.stallholder, stock: item.stock, description: item.description, price: item.price, floor: floor, till: till, paymentType: paymentType});
}

function addTransactionsImpl(jwt: JWT, items: TillItem[], floor: number, till: string, paymentType: number) {

  log.debug("addTransactionsImpl", items);

  return callWithAuthorization(POST, jwt, "addTransactions", {}, {transactions: items, floor: floor, till: till, paymentType: paymentType});
}

function fetchData(jwt: JWT, call: string, data: {}) {

  return callWithAuthorization(POST, jwt, call, {}, data)
  .then(function(value) {

    //log.info("fetched: " + JSON.stringify(value.data));
    return value.data;
  })
  .catch(function(error) {
    console.error(error);
  });
}

async function callWithAuthorization(method: string, token:JWT, resource:string, params:{}, data:{}) {
  log.info("calling", resource);

  const options = {
    method: method,
    data: data,
    mode: "cors",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json"
    },
    url: API_URL + resource //convertMapToURLParams(params)
  };  

  //log.info("callWithAuthorization 2:" + JSON.stringify(options));

  return axios(options);
}
/*
function convertMapToURLParams(params:{<string, any>}) {
  if (params === undefined) return "";

  if  (Object.keys(params).length === 0) return "";

  return (
    "?" +
    Object.keys(params)
      .map(key => key + "=" + params[key])
      .join("&")
  );
}*/

export default TillAPIServiceContext;
